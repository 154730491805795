import {useContext} from 'react'
import {useLocation, Link} from 'react-router-dom'
import {AppContext} from "../../store/context";
import {Device} from "./Device";

import logo from "../../static/img/logo.svg"


import info from "../../static/img/icons/info.svg"
import chat from "../../static/img/icons/chat.svg"

export const Sidebar = () => {
    const {context} = useContext(AppContext);

    const {pathname} = useLocation();

    let step = 0;

    switch (true) {
        case pathname.includes('/request/visual'):
            step = 1;
            break;
        case pathname.includes('/request/autograding'):
            step = 2;
            break;
        case pathname.includes('/request/tech'):
            step = 3;
            break;
        case pathname.includes('/request/customer'):
            step = 4;
            break;
        case pathname.includes('/request/success'):
            step = 5;
            break;
    }


    return <div className="request-sidebar">
        <div className="request-sidebar__inner">
            <div className="request-sidebar__logo">
                <Link to={'/'}>
                    <img src={logo} alt=""/>
                </Link>
            </div>
            <div className="request-sidebar__steps">
                <div className={`request-sidebar__step ${step === 0 ? 'current': ''} ${step >= 0 ? 'active': ''}`}>
                    <div className="request-sidebar__step-point"/>
                    <div className="request-sidebar__step-text">Оценка устройства</div>
                </div>
                <div className={`request-sidebar__step ${step === 1 ? 'current': ''} ${step >= 1 ? 'active': ''}`}>
                    <div className="request-sidebar__step-point"/>
                    <div className="request-sidebar__step-text">Визуальная оценка</div>
                </div>
                <div className={`request-sidebar__step ${step === 2 ? 'current': ''} ${step >= 2 ? 'active': ''}`}>
                    <div className="request-sidebar__step-point"/>
                    <div className="request-sidebar__step-text">Фото устройства</div>
                </div>
                <div className={`request-sidebar__step ${step === 3 ? 'current': ''} ${step >= 3 ? 'active': ''}`}>
                    <div className="request-sidebar__step-point"/>
                    <div className="request-sidebar__step-text">Техническая оценка</div>
                </div>
                <div className={`request-sidebar__step ${step === 4 ? 'current': ''} ${step >= 4 ? 'active': ''}`}>
                    <div className="request-sidebar__step-point"/>
                    <div className="request-sidebar__step-text">Данные клиента</div>
                </div>
                <div className={`request-sidebar__step ${step === 5 ? 'current': ''} ${step >= 5 ? 'active': ''}`}>
                    <div className="request-sidebar__step-point"/>
                    <div className="request-sidebar__step-text">Готово</div>
                </div>
            </div>
            <Device/>
            <div className="request-sidebar__footer">
                <div className="request-sidebar__footer-link" onClick={() => {context.modal.dispatch({type: 'toggleInstruction'})}}>
                    <div className="request-sidebar__footer-icon">
                        <img src={info} alt=""/>
                    </div>
                    <div className="request-sidebar__footer-text">Инструкция</div>
                </div>
                <a className="request-sidebar__footer-link" href={'https://t.me/SdalKupil'} target={'_blank'}>
                    <div className="request-sidebar__footer-icon">
                        <img src={chat} alt=""/>
                    </div>
                    <div className="request-sidebar__footer-text">Помощь</div>
                </a>
            </div>
        </div>
    </div>
}