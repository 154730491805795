import React from 'react'

import {AppContext} from "../../store/context";
import {VisualForm} from '../../components/request/form/Visual'


export class Visual extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        setTimeout(() => {
            this.setState({loaded: true})
        }, 100)
    }

    goNext() {
        this.setState({loaded: false});
        this.context.context.request.dispatch({type: 'save'});

        const isAutograding = this.context.context.user.state.autograding == 1;
        const isRobot = this.context.context.user.state.isRobot == true;

        const isPhone = this.context.context.request.state.device.type == 1;
        const isTablet = this.context.context.request.state.device.type == 2;
        const isWatches = this.context.context.request.state.device.type == 3;

        const ignoreTech = this.context.context.request.state.device.neva_ignore == 1;
        const isBroken = this.context.context.request.state.grade.grade === 'g'


        setTimeout(() => {
            if (isRobot) {
                this.props.history.push('/request/tech/code');
                return;
            }

            if (isAutograding && (isPhone || isTablet) && !isBroken) {
                this.props.history.push('/request/autograding');
                return;
            }

            if (ignoreTech || isWatches) {
                this.props.history.push('/request/customer');
                return
            }

            this.props.history.push('/request/tech')
        }, 300)
    }

    goMain() {
        const request = this.context.context.request.state.id;
        this.context.context.processor.request.cancel(request, "visual")
            .then(() => {
                this.props.history.push('/');
            })
    }

    render() {
        return <div className={`reveal ${this.state.loaded ? 'reveal--loaded' : ''}`}>
            <VisualForm success={() => this.goNext()} reject={() => this.goMain()}/>
        </div>
    }
}

Visual.contextType = AppContext;