import React from 'react'
import {AppContext} from "../store/context";

import chat from "../static/img/icons/chat.svg"
import macbook from "../static/img/icons/macbook.svg"
import info from "../static/img/icons/info.svg"
import load from "../static/img/icons/load.svg"

export class Main extends React.Component {
    constructor() {
        super()
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        if (!this.context.context.user.state.isAuth) {
            window.location.replace('/auth');
            return
        }
    }

    render() {
        return <div className="main">
            <div className="main-top">

            </div>
            <div className="main-logo">
            </div>
            <div className="main-text">
                Удобная online-площадка оценки стоимости устройств  с возможностью обмена на новые  или наличные деньги
            </div>
            <div className="main-form">
                <div className="btn btn-orange" onClick={() => this.props.history.push('/request')}>
                    <span className="text"><strong>Оценить устройство</strong></span>
                    <span className="icon">
                         <img src={macbook} alt=""/>
                    </span>
                </div>
                <div className="btn btn-white" onClick={() => {this.context.context.modal.dispatch({type: 'toggleInstruction'})}}>
                    <span className="text">Инструкция</span>
                    <span className="icon">
                         <img src={info} alt=""/>
                    </span>
                </div>
                <a className="btn btn-white" href={'https://t.me/SdalKupil'} target={'_blank'}>
                    <span className="text">Помощь</span>
                    <span className="icon">
                        <img src={chat} alt=""/>
                    </span>
                </a>
                {this.context.context.user.state.isGoodcom
                    ? <div className="btn btn-white" onClick={() => {this.context.context.modal.dispatch({type: 'toggleSearcher'})}}>
                        <span className="text">Загрузить с сайта</span>
                        <span className="icon">
                         <img src={load} alt=""/>
                     </span>
                    </div> : null}
            </div>
        </div>
    }
}

Main.contextType = AppContext;