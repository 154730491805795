import React from 'react'

import {AppContext} from "../../store/context";
import {AutogradeForm} from '../../components/request/form/Autograde'


export class Autograde extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        setTimeout(() => {
            this.setState({loaded: true})
        }, 100)
    }

    goNext() {
        this.setState({loaded: false})

        setTimeout(() => {
            const ignoreTech = this.context.context.request.state.device.neva_ignore == 1;

            if (ignoreTech) {
                this.props.history.push('/request/customer');
                return;
            }

            this.props.history.push('/request/tech')
        }, 300)
    }

    goMain() {
        const request = this.context.context.request.state.id;
        this.context.context.processor.request.cancel(request, "autograde")
            .then(() => {
                this.props.history.push('/');
            })
    }

    render() {
        return <div className={`reveal ${this.state.loaded ? 'reveal--loaded' : ''}`}>
            <AutogradeForm success={() => this.goNext()} reject={() => this.goMain()}/>
        </div>
    }
}

Autograde.contextType = AppContext;