import {AuthAware} from "./auth-aware";
import {API_BASE_URL} from "../const";

export class Catalog extends AuthAware{
    getBrands(type) {
        return this.fetchJSON(`${API_BASE_URL}/v1/catalog/brands`, {
            method: 'post',
            body: JSON.stringify({
                type: type
            })
        })
    }

    getModels(type, brand) {
        return this.fetchJSON(`${API_BASE_URL}/v1/catalog/models`, {
            method: 'post',
            body: JSON.stringify({
                type: type,
                id_brand: brand
            })
        })
    }

    getModel(id) {
        return this.fetchJSON(`${API_BASE_URL}/v1/catalog/model`, {
            method: 'post',
            body: JSON.stringify({
                id: id,
            })
        })
    }
}