import React, {useContext, useEffect, useState} from 'react'
import {AppContext} from "../../../store/context";
import {ResultPopup, ResultSection} from "./Result";
import {Device} from "../Device";
import {Loader} from "../../Loader";
import {Collapse} from 'react-collapse';
import {Link} from 'react-router-dom'

import checkmark from '../../../static/img/icons/checkmark.svg'
import checkbox from '../../../static/img/icons/checkbox.svg'
import checkbox_checked from '../../../static/img/icons/checkbox_checked.svg'

export const VisualForm = ({success, reject}) => {
    const {context} = useContext(AppContext);

    const [showResult, setShowResult] = useState(false);

    const [state, setState] = useState({
        sections: [],
        questions: {}
    });

    useEffect(() => {
        const request = context.request.state.id;

        if (!request) {
            reject()
        }

        context.processor.request.getVisualTestingQuestions(request).then(res => {
            const questions = reformatQuestions(res.questions);

            setState({
                sections: Object.keys(questions),
                questions: questions
            })
        }).catch(err => {
            context.toast.error(err.message);
            reject()
        })
    }, []);

    const reformatQuestions = (questions) => {
        const values = [];

        for (const [key, value] of Object.entries(questions)) {
            if (!values.hasOwnProperty(key)) {
                values[key] = {
                    title: value.title,
                    values: []
                }
            }

            for (const [k, v] of Object.entries(value.child)) {
                values[key].values.push({
                    parent: k,
                    title: v.title,
                    child: v.child,
                    value: false
                })
            }
        }

        return values;
    };

    const save = (key, el) => {
        const questions = state.questions;

        questions[key].values = el;

        setState({
            ...state,
            questions: questions
        })
    };

    const checkResults = () => {
        const request = context.request.state.id;
        const questions = {};

        if (!request) {
            reject()
        }

        try {
            for (const [key, value] of Object.entries(state.questions)) {
                questions[key] = {};
                value.values.map(el => {
                    if (el.value === false) {
                        throw new Error('');
                        return;
                    }
                    questions[key][el.parent] = el.value
                })
            }
        } catch (e) {
            context.toast.error("Необходимо дать ответ на все вопросы");
            return;
        }

        context.processor.request.sendVisualTestingQuestions(request, questions).then(res => {
            context.request.dispatch({type: 'setGrade', payload: {grade: res.grade, price: res.price}});
            setShowResult(true)
        }).catch(err => {
            context.toast.error(err.message);
            // reject()
        })
    }


    return showResult && context.settings.isDesktop ? <ResultSection success={success} reject={reject}/> : <>
        <div className="request-title">Визуальная оценка</div>
        <div className="request-form request-form--visual-form">
            <div className="form-device">
                <Device/>
            </div>
            <div className="visual-form">
                <div className="visual-form__title">Необходимо ответить на несколько вопросов</div>
                <div className="visual-form__questions">
                    {state.sections.length > 0 ? state.sections.map(s => {
                        return <QuestionGroup
                            title={state.questions[s].title}
                            group={state.questions[s].values}
                            callback={(group) => {
                                save(s, group)
                            }}
                        />
                    }) : <div className="ld"><Loader/></div>}
                </div>
                <div className="visual-form__buttons">
                    <Link to={'/request'} className="btn btn-white">
                        <span className="text">Назад</span>
                    </Link>
                    <div className="btn btn-orange" onClick={() => {
                        checkResults()
                    }}>
                        <span className="text">Далее</span>
                    </div>
                </div>
            </div>
        </div>
        {showResult && !context.settings.isDesktop
            ? <ResultPopup success={success} reject={reject}/>
            : null}
    </>
};

const QuestionGroup = ({title, group, callback}) => {
    if (group.length === 0) {
        return <></>;
    }

    const cl = (key, el) => {
        group[key].value = +!el.value;

        callback(group)
    };

    const subCl = (key, value) => {
        group[key].value = value;

        callback(group)
    }

    return <div className={'question-group'}>
        <div className={'question-group__title'}>{title}</div>
        <div className={'question-group__values'}>
            {
                group.map((el, key) => {
                    return el.child.length === 0
                        ? <Question callback={() => {
                            cl(key, el)
                        }} question={el} checked={el.value === 1}/>
                        : <QuestionSubGroup callback={(value) => {
                            subCl(key, value)
                        }} sub={el}/>
                })
            }
        </div>
    </div>


};

const QuestionSubGroup = ({sub, callback}) => {
    const [open, setOpen] = useState(true);
    const [answered, setAnswered] = useState(false)
    const child = Object.values(sub.child);

    const cl = (k) => {
        setAnswered(true);
        callback(k)
    };

    return <div className={`question-subgroup`}>
        <div className="question-subgroup__title" onClick={() => {
            // setOpen(!open)
        }}>
            <div className="question-subgroup__title--text">
                {sub.title}
            </div>
            {answered ? <div className="question-subgroup__title--icon">
                <img src={checkmark} alt=""/>
            </div> : <i/>}
        </div>
        <div className="question-subgroup__inner">
            <Collapse isOpened={open}>
                {child.map((v, k) => {
                    return <Question callback={() => {
                        cl(k)
                    }} checked={k === sub.value} question={v}/>
                })}
            </Collapse>
        </div>
    </div>
};

const Question = ({callback, checked, question}) => {
    const cl = () => {
        callback()
    };

    return <div className="question-item" onClick={cl}>
        <div className="question-item__title">{question.title}</div>
        <div className="question-item__checkbox">
            <img src={checked ? checkbox_checked : checkbox} alt=""/>
        </div>
    </div>
};