import React, {useContext} from "react";
import {ToastContainer} from 'react-toastify';
import {Helmet} from "react-helmet";
import {TopBar} from "../components/TopBar";
import {Instruction} from "../components/Instruction";
import {Searcher} from "../components/Searcher";
import logo from "../static/img/logo.svg"
import 'react-toastify/dist/ReactToastify.css';


const Main = ({className, children}) => (
    <div className={className}>{children}</div>
);

const AsideLayout = ({seo, children}) => {
    return <React.Fragment>
            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />
            </Helmet>
            <Main className="aside">
                <div className="aside-image"/>
                <div className="aside-content">
                    <div className="aside-inner">
                        <TopBar/>
                        <div className="aside-logo">
                            <img src={logo} alt=""/>
                        </div>
                        {children}
                    </div>
                </div>
                <Instruction/>
                <Searcher/>
            </Main>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        </React.Fragment>
};

export default AsideLayout;