import {AuthAware} from "./auth-aware";
import {API_BASE_URL} from "../const";

export class Request extends AuthAware {
    uploadImages(id, data) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/autograding/upload`, {
            method: 'POST',
            body: data
        }, true)
    }

    checkGrade(id, entity) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/autograding/check`, {
            method: 'POST',
            body: JSON.stringify({entityId: entity})
        })
    }

    checkGradeByCode(id, code) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/autograding/check`, {
            method: 'POST',
            body: JSON.stringify({code: code})
        })
    }

    sendSMS(id) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/customer/sms`, {
            method: 'post',
        })
    }

    confirmCode(id, code) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/customer/confirm`, {
            method: 'post',
            body: JSON.stringify({code: code})
        })
    }

    saveCustomer(id, data) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/customer`, {
            method: 'post',
            body: JSON.stringify(data)
        })
    }

    getTechCode(id) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/tech/code`)
    }

    checkTechResults(id, key) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/tech/check`, {
            method: 'post',
            body: JSON.stringify({key: key})
        })
    }

    createRequest(data) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request`, {
            method: 'post',
            body: JSON.stringify(data)
        })
    }

    checkApprove(id) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/approve/check`)
    }

    getVisualTestingQuestions(id) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/visual`)
    }

    sendVisualTestingQuestions(id, data) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/visual/save`, {
            method: 'post',
            body: JSON.stringify({
                testing: data
            })
        })
    }

    load(id) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}`)
    }

    search(code) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/search `, {
            method: 'post',
            body: JSON.stringify({
                code: code
            })
        })
    }

    pay(id) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/pay `, {
            method: 'post',
        })
    }

    cancel(id, cancel_step) {
        return this.fetchJSON(`${API_BASE_URL}/v1/request/${id}/cancel`, {
            method: 'post',
            body: JSON.stringify({
                cancel_step: cancel_step
            })
        })
    }
}