import Cookies from "js-cookie";

export default class Token {
    static setToken(token) {
        Cookies.set('apikey', token, {expires: 30})
    }

    static getToken() {
        return Cookies.get('apikey')
    }

    static removeToken() {
        Cookies.remove('apikey');
    }

    static logout() {
        this.removeToken();
    }

    static getVersion() {
        return Cookies.get('version') || 0
    }

    static setVersion(v) {
        Cookies.set('version', v, {expires: 365})
    }
}