import {useContext, useEffect, useState} from 'react'
import {AppContext} from "../../../store/context";
import {LoaderSmall} from "../../Loader";
import {Device} from "../Device";

export const ApproveForm = ({callback, reject}) => {
    const {context} = useContext(AppContext);

    const [inProgress, setProgress] = useState(false);

    useEffect(() => {
        const request = context.request.state.id;

        if (!request) {
            reject()
        }
    }, []);

    const checkResults = () => {
        if (inProgress) {
            return
        }

        const request = context.request.state.id;

        if (!request) {
            reject()
        }

        setProgress(true);

        context.processor.request.checkApprove(request).then(res => {
            callback()
        }).catch(err => {
            context.toast.error(err.message)
        }).finally(() => {
            setProgress(false)
        })
    }

    return <>
            <div className="request-title">Ожидание подтверждения</div>
            <div className="request-form request-form--tech-form">
                <div className="form-device">
                    <Device/>
                </div>
                <div className="tech-form">
                    <div className="tech-form__text">По заявке <strong>{context.request.state.id}</strong> требуется подтверждение администратора.<br/><br/>
                        Пожалуйста, свяжитесь с администратором и проверьте статус после подтверждения заявки.
                    </div>
                    <div className="tech-form__buttons">
                        <div className="btn btn-orange" onClick={() => {
                            checkResults()
                        }}>
                            {inProgress ? <LoaderSmall color={'white'}/> : <span className="text">Далее</span>}
                        </div>
                    </div>
                </div>
            </div>
        </>
}