import {useEffect} from 'react'
import {AppContext, AppContextValue} from './store/context'

import Routes from './routes/routes'
import Token from "./components/static/token";
import {VERSION} from "./const";

const App = () => {
    const context = AppContextValue();

    useEffect(() => {
        if (parseInt(Token.getVersion()) !== parseInt(VERSION)) {
            Token.logout();
            context.request.dispatch({type: 'clear'});

            localStorage.removeItem('user');
            localStorage.removeItem('request');

            Token.setVersion(VERSION);

            window.location.reload()
        }

        context.request.dispatch({type: 'load'});
        if (Token.getToken()) {
            let userdata = JSON.parse(localStorage.getItem('user'));

            context.user.dispatch({type: 'setUser', payload: userdata || {}});
        } else {
            context.user.dispatch({type: 'loaded'});
        }
    }, []);

    return <AppContext.Provider value={{context}}>
        <Routes/>
    </AppContext.Provider>
};

export default App;
