import React from 'react'
import {AppContext} from "../store/context";

export class Page404 extends React.Component {
    constructor() {
        super()
    }

    render() {
        return <div/>
    }
}

Page404.contextType = AppContext;