import React from 'react'
import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode'
import {AppContext} from "../../store/context";
import printer from '../../static/img/icons/printer.svg'
import success from '../../static/img/success.svg'
import {ResultPopup} from "../../components/request/form/Result";

export class Success extends React.Component {
    constructor() {
        super()

        this.componentRef = React.createRef();
        this.state = {
            request: null,
            sbpModal: {
                open: false,
            }
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.request.load(this.context.context.request.state.id).then(res => {
            this.setState({request: res})
        })
    }

    render() {
        return <>
            <div className="request-title">Ура</div>
            <div className="request-form request-form--success">
                <div className="success-form">
                    <div className="success-form__image">
                        <img src={success} alt=""/>
                    </div>
                    <div className="success-form__text">
                        <div className="success-form__text-title">
                            Заявка {this.context.context.request.state.id} успешно создана
                        </div>
                        {this.state.request ?
                            <>
                                <div className="success-form__text-sub">
                                    Устройство:&nbsp;<strong>{this.state.request.device}</strong>
                                </div>
                                {this.state.request.imei ?
                                    <div className="success-form__text-sub">
                                        IMEI:&nbsp;<strong>{this.state.request.imei}</strong>
                                    </div> : null}
                                <div className="success-form__text-sub">
                                    Состояние:&nbsp;<strong>{this.state.request.grade.toUpperCase()}</strong>
                                </div>
                                <div className="success-form__text-sub">
                                    Стоимость:&nbsp;<strong>{this.state.request.price} ₽</strong>
                                </div>
                            </>
                            : null}
                    </div>
                    <div className="success-form__buttons">
                        <div className="btn btn-white" onClick={() => {
                            window.location.replace('/request')
                        }}>
                            <span className="text">Новая заявка</span>
                        </div>
                        <Print ref={this.componentRef}/>
                        {this.state.request && this.state.request.card && this.state.request.sbpActive ?
                        <div className="btn btn-orange" onClick={() => {
                            this.context.context.modal.dispatch({type: "togglePay"})
                        }}>
                            Оплатить
                        </div> : null}
                    </div>
                </div>
                {this.state.request ? <ComponentToPrint request={this.state.request} ref={this.componentRef}/> : null}
            </div>
        </>
    }
}

const Print = React.forwardRef((props, ref) => {
    return <ReactToPrint
        trigger={() => <div className="btn btn-transparent">
            <span className="text">Распечатать заявку</span>
            <span className="icon"><img src={printer} alt=""/></span>
        </div>}
        content={() => ref.current}
    />
})

export const ComponentToPrint = React.forwardRef((props, ref) => {
    const request = props.request;

    return (
        <div id={"section-to-print"} ref={ref}>
            <h1>Информация по заявке</h1>
            <ul>
                <li>
                    <span>№ заявки</span>
                    <strong>{request.id}</strong>
                </li>
                <li>
                    <span>Модель</span>
                    <strong>{request.device}</strong>
                </li>
                <li>
                    <span>Партнер</span>
                    <strong>{request.partner}</strong>
                </li>
                <li>
                    <span>Пользователь</span>
                    <strong>{request.user}</strong>
                </li>
                <li>
                    <span>Грейд</span>
                    <strong>{request.grade.toUpperCase()}</strong>
                </li>
                {request.imei ? <li>
                    <span>IMEI</span>
                    <strong>{request.imei}</strong>
                </li> : null}
                {request.serial_number ? <li>
                    <span>Серийный номер</span>
                    <strong>{request.serial_number}</strong>
                </li> : null}
                {request.barcode ? <Barcode format={"CODE39"} value={request.barcode}/> : null}
            </ul>
        </div>
    );
});


Success.contextType = AppContext;