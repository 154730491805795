import {AuthAware} from "./auth-aware";
import {API_BASE_URL} from "../const";

export class Auth extends AuthAware{
    loadUser(data) {
        return this.fetchJSON(`${API_BASE_URL}/v1/auth`, {
            method: 'post',
            body: JSON.stringify(data)
        })
    }
}