import {useContext, useEffect, useState} from 'react'
import {AppContext} from "../store/context";

import close from '../static/img/icons/close.svg'
import {LoaderSmall} from "./Loader";

export const PayModal = () => {
    const {context} = useContext(AppContext);

    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        if (context.modal.state.pay) {
            setShow(true);
            setTimeout(() => {
                setOpen(true)
            }, 100)
        } else {
            setOpen(false);
            setTimeout(() => {
                setShow(false)
            }, 100)
        }
    }, [context.modal.state.pay]);

    const process = () => {
        if(inProgress) {
            return
        }

        setInProgress(true);

        context.processor.request.pay(context.request.state.id).then(res => {
            context.modal.dispatch({type: 'togglePay'});
            context.toast.success(res.message);
            setInProgress(false);
        }).catch(err => {
            setInProgress(false);
            context.toast.error(err.message);
        })
    }


    return context.request.state.id && context.request.state.grade && context.request.state.device ? <div className={`searcher ${show ? 'show' : ''} ${open ? 'open' : ''}`}>
        <div className="searcher-container">
            <div className="searcher-close" onClick={() => {context.modal.dispatch({type: 'togglePay'})}}>
                <img src={close} alt=""/>
            </div>
            <div className="searcher-title">Будет произведена оплата на банковскую карту.</div>
            <div className="searcher-body" style={{textAlign: 'center', fontSize: 'x-large'}}>
                Сумма <strong>{context.request.state.grade.price || 0}₽</strong><br/> <br/> Принято устройство <strong>{context.request.state.device.exchange_name || '-'}</strong>.
            </div>
            <div className="searcher-buttons">
                <div className="btn btn-orange" onClick={() => {process()}}>
                    {inProgress ? <LoaderSmall color={'white'}/> : <span className="text">Продолжить</span>}
                </div>
                {!inProgress ?
                <div className="btn btn-purple" onClick={() => {context.modal.dispatch({type: 'togglePay'})}}>
                    <span className="text">Отмена</span>
                </div> : null}
            </div>
        </div>
    </div> : null
}