import React from 'react'

import {AppContext} from "../../store/context";
import {DeviceForm} from "../../components/request/form/Device";

export class Create extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false
        }
    }
    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        setTimeout(() => {
            this.context.context.request.dispatch({type: 'clear'});

            this.setState({loaded: true})
        }, 100)
    }

    goNext(isApproved) {
        this.setState({loaded: false});
        this.context.context.request.dispatch({type: 'save'});
        setTimeout(() => {
            isApproved
                ? this.props.history.push('/request/visual')
                : this.props.history.push('/request/approve');
        }, 300)
    }

    render() {
        return <div className={`reveal ${this.state.loaded ? 'reveal--loaded' : ''}`}>
            <DeviceForm callback={(isApproved) => this.goNext(isApproved)}/>
        </div>
    }
}

Create.contextType = AppContext;