import {useContext, useEffect, useState} from 'react'
import {AppContext} from "../../../store/context";
import {LoaderSmall} from "../../Loader";
import {Device} from "../Device";

import InputMask from 'react-input-mask';
import OtpInput from 'react-otp-input';


import checkbox from '../../../static/img/icons/checkbox.svg'
import checkbox_checked from '../../../static/img/icons/checkbox_checked.svg'

export const CustomerForm = ({success, reject}) => {

    const [isConfirm, setConfirm] = useState(false);
    const [phone, setPhone] = useState(null);

    return <>
        <div className="request-title">Данные клиента</div>
        <div className="form-device">
            <Device/>
        </div>
        <div className="request-form request-form--customer-form">

            {!isConfirm
                ? <CustomerDataForm
                    success={p => {
                        setConfirm(true);
                        setPhone(p)
                    }}
                    reject={reject}/>
                : <CustomerConfirmForm
                    phone={phone}
                    success={success}
                    reject={() => {
                        setConfirm(false)
                    }}/>}
        </div>
    </>
}

export const CustomerDataForm = ({success, reject}) => {
    const {context} = useContext(AppContext);

    const ignoreTech = context.request.state.device.neva_ignore == 1;
    const isPhone = context.request.state.device.type == 1;

    useEffect(() => {
        context.processor.request.load(context.request.state.id).then(res => {
            setBadTech(res.serial === 'unknown' && res.imei === 'nosimslots')
        })
    }, []);

    const [badTech, setBadTech] = useState(false);
    const [inProgress, setProgress] = useState(false);
    const [checked, setChecked] = useState(false);
    const [data, setData] = useState({
        fio: null,
        phone: null,
        passport: null,
        card: null,
        imei: null,
        serial: null
    });

    const handlePhone = (e) => {
        setData({...data, phone: e.currentTarget.value})
    }

    const handleFio = (e) => {
        setData({...data, fio: e.target.value})
    }

    const handleCard = (e) => {
        setData({...data, card: e.target.value})
    }

    const handlePassport = (e) => {
        setData({...data, passport: e.target.value})
    }

    const handleImei = (e) => {
        setData({...data, imei: e.target.value})
    }

    const handleSerial = (e) => {
        setData({...data, serial: e.target.value})
    }

    const saveForm = () => {
        if (inProgress) {
            return
        }

        const request = context.request.state.id;

        if (!request) {
            reject()
        }

        if(((ignoreTech || badTech) && !isPhone) && !data.serial) {
            context.toast.error('Пожалуйста, укажите серийный номер');
            return;
        }

        if(((ignoreTech || badTech) && isPhone) && !data.imei) {
            context.toast.error('Пожалуйста, укажите IMEI');
            return;
        }

        setProgress(true);

        context.processor.request.saveCustomer(request, data).then(res => {
            success(data.phone)
        }).catch(err => {
            setProgress(false);
            context.toast.error(err.message)
        })
    }

    const showCardField = () => {
        if (checked) {
            setData({...data, card: null})
        }

        setChecked(!checked)
    };

    return <div className="customer-form">
        <div className="customer-form__inputs">
            <div className="input">
                <input type="text" name="fio" value={data.fio} onChange={(e) => {
                    handleFio(e)
                }} placeholder={'ФИО клиента'}/>
            </div>
            <div className="input">
                <input type="text" name="passport" value={data.passport} onChange={(e) => {
                    handlePassport(e)
                }} placeholder={'Серия и номер паспорта'}/>
            </div>
            <div className="input">
                <InputMask value={data.phone} placeholder={'+7 (___) ___ __ __'} mask="+7 (999) 999 99 99"
                           maskChar="_" onChange={(e) => {
                    handlePhone(e)
                }}/>
            </div>
            {(ignoreTech || badTech) && isPhone ?
            <div className="input">
                <input type="text" name="imei" value={data.imei} onChange={(e) => {
                    handleImei(e)
                }} placeholder={'IMEI'}/>
            </div> : null}
            {(ignoreTech || badTech) && !isPhone ?
            <div className="input">
                <input type="text" name="serial" value={data.serial} onChange={(e) => {
                    handleSerial(e)
                }} placeholder={'Серийный номер'}/>
            </div> : null}
            {context.user.state.showCard == 1 ? <>
                <div className="customer-form__checkbox" onClick={() => {
                    showCardField()
                }}>
                    <div className="customer-form__checkbox-title">Оплата на карту</div>
                    <div className="customer-form__checkbox-icon">
                        <img src={checked ? checkbox_checked : checkbox} alt=""/>
                    </div>
                </div>
                {checked ? <div className="input">
                    <input type="text" name="card" value={data.card} onChange={(e) => {
                        handleCard(e)
                    }} placeholder={'Номер карты для перевода'}/>
                </div> : null} </> : null}

        </div>
        <div className="customer-form__buttons">
            <div className="btn btn-orange" onClick={() => {
                saveForm()
            }}>
                {inProgress ? <LoaderSmall color={'white'}/> : <span className="text">Далее</span>}
            </div>
        </div>
    </div>
}

export const CustomerConfirmForm = ({success, reject, phone}) => {
    const {context} = useContext(AppContext);

    const [inProgress, setProgress] = useState(false);
    const [timeLeft, setTimeLeft] = useState(60*5);

    const [otp, setOtp] = useState();

    useEffect(() => {
        setTimeLeft(60*5)
    }, []);

    useEffect(() => {
        if (!timeLeft) {
            return;
        }
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    useEffect(() => {
        if (otp && otp.length === 4) {
            if (inProgress) {
                return
            }

            const request = context.request.state.id;

            if (!request) {
                reject()
            }

            setProgress(true);

            context.processor.request.confirmCode(request, otp).then(res => {
                success()
            }).catch(err => {
                setOtp(null)
                setProgress(false);
                context.toast.error(err.message);
            })
        }
    }, [otp]);

    const sendCode = () => {
        if (inProgress) {
            return
        }

        const request = context.request.state.id;

        if (!request) {
            reject()
        }

        context.processor.request.sendSMS(request).then(res => {
            setTimeLeft(60*5);
        }).catch(err => {
            context.toast.error(err.message)
        })
    };

    return <div className="customer-confirm-form">
        <div className="customer-confirm-form__text">Для подписания договора используется СМС</div>
        <div className="customer-confirm-form__phone">Номер телефона: {phone}</div>
        <div className="customer-confirm-form__code">
            <OtpInput numInputs={4}
                      inputStyle={'customer-confirm-form__code-block'}
                      placeholder={'----'}
                      value={otp}
                      isDisabled={inProgress}
                      onChange={(otp) => {
                          setOtp(otp)
                      }}/>
        </div>
        <div className="customer-confirm-form__send">
            {!timeLeft ?
                <div className="customer-confirm-form__send-button">
                    <div className="btn btn-purple" onClick={() => {
                        sendCode()
                    }}>
                        <span className="text">Отправить код</span>
                    </div>
                </div> :
                <>
                    <div className="customer-confirm-form__send-text">Не пришел код?</div>
                    <div className="customer-confirm-form__send-counter">Повторная отправка
                        через: {timeLeft} сек
                    </div>
                </>}
        </div>
        <div className="customer-confirm-form__buttons">
            <div className="btn btn-white" onClick={() => {
                reject()
            }}>
                {inProgress ? <LoaderSmall color={'purple'}/> : <span className="text">Назад</span>}
            </div>
        </div>
    </div>
}