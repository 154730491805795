import {useContext, useEffect, useRef, useState} from 'react'
import {AppContext} from "../../../store/context";
import {ResultPopup, ResultSection} from "./Result";
import {Device} from "../Device";
import {LoaderSmall} from "../../Loader";

import upload from '../../../static/img/icons/upload.svg'

export const AutogradeForm = ({success, reject}) => {
    const {context} = useContext(AppContext);

    return  <AutogradeDesktop success={success} reject={reject}/>
    // return context.settings.isDesktop
    //     ? <AutogradeDesktop success={success} reject={reject}/>
    //     : <AutogradeMobile success={success} reject={reject}/>
}

const AutogradeDesktop = ({success, reject}) => {
    const {context} = useContext(AppContext);

    const [code, setCode] = useState(null);
    const [inProgress, setProgress] = useState(false);
    const [showResult, setShowResult] = useState(false);

    useEffect(() => {
        const request = context.request.state.id;

        if (!request) {
            reject()
        }

        context.processor.request.getTechCode(request).then(res => {
            setCode(res.key);
        }).catch(err => {
            context.toast.error(err.message)
        })
    }, []);

    const checkResults = () => {
        if (inProgress) {
            return
        }

        const request = context.request.state.id;

        if (!request) {
            reject()
        }

        setProgress(true);

        context.processor.request.checkGradeByCode(request, code)
            .then(res => {
                context.request.dispatch({type: 'setGrade', payload: {grade: res.grade, price: res.price}});
                setShowResult(true);
            })
            .catch(err => {
                setProgress(false);
                context.toast.error(err.message)
            });
    }

    return showResult && context.settings.isDesktop ? <ResultSection success={success} reject={reject}/> : <>
        <div className="request-title">Фото устройства</div>
        <div className="request-form request-form--photos-form">
            <div className="photos-form">
                <div className="photos-form__text--desktop">Для прохождения визуальной оценки, необходимо сделать фотографии приложением NSYS Autograding</div>
                <div className="photos-form__code">
                    <div className="photos-form__code-text">После запуска введи код:</div>
                    <div className="photos-form__code-code">{!code ? <LoaderSmall/> : code}</div>
                </div>
            </div>
            <div className="photos-form__buttons">
                <div className="btn btn-orange" onClick={() => {
                    checkResults()
                }}>
                    {inProgress ? <LoaderSmall color={'white'}/> : <span className="text">Далее</span>}
                </div>
            </div>
        </div>
        {showResult && !context.settings.isDesktop
            ? <ResultPopup success={success} reject={reject}/>
            : null}
    </>
}

const AutogradeMobile = ({success, reject}) => {
    const {context} = useContext(AppContext);

    const [inProgress, setProgress] = useState(false);
    const [showResult, setShowResult] = useState(false);

    const [tmpImage, setTmpImage] = useState(null);

    const [images, setImages] = useState({
        fr: null,
        rg: null,
        bk: null,
        lf: null,
        tp: null,
        bt: null
    });

    const frInput = useRef();
    const rgInput = useRef();
    const bkInput = useRef();
    const lfInput = useRef();
    const tpInput = useRef();
    const btInput = useRef();

    const form = useRef();

    useEffect(() => {
        if (!tmpImage) {
            return
        }

        const img = images;

        if (tmpImage.img === null) {
            img[tmpImage.pos] = null;
            setTmpImage(null);
            setImages(img);
            return;
        }


        const objectUrl = URL.createObjectURL(tmpImage.img);
        img[tmpImage.pos] = objectUrl;
        setTmpImage(null);
        setImages(img);

        return () => URL.revokeObjectURL(objectUrl)
    }, [tmpImage])

    const uploadImage = (type) => {
        switch (type) {
            case 'fr':
                proceedUploadImage(frInput);
                break;
            case 'rg':
                proceedUploadImage(rgInput);
                break;
            case 'bk':
                proceedUploadImage(bkInput);
                break;
            case 'lf':
                proceedUploadImage(lfInput);
                break;
            case 'tp':
                proceedUploadImage(tpInput);
                break;
            case 'bt':
                proceedUploadImage(btInput);
                break;
        }
    };

    const proceedUploadImage = (ref) => {
        ref.current.click();
    }

    const onSelectFile = (e, pos) => {
        if (!e.target.files || e.target.files.length === 0) {
            setTmpImage({img: null, pos: pos})
            return
        }

        setTmpImage({img: e.target.files[0], pos: pos})
    };

    const checkResults = () => {
        if (inProgress) {
            return
        }

        const request = context.request.state.id;

        if (!request) {
            reject()
        }


        const data = new FormData(form.current);

        setProgress(true);

        context.processor.request.uploadImages(request, data).then(res => {
            context.processor.request.checkGrade(request, res.entity)
                .then(res => {
                    context.request.dispatch({type: 'setGrade', payload: {grade: res.grade, price: res.price}});
                    setShowResult(true);
                })
                .catch(err => {
                    setProgress(false);
                    context.toast.error(err.message)
                })
        }).catch(err => {
            setProgress(false);
            context.toast.error(err.message)
        })
    }

    return <>
        <div className="request-title">Фото устройства</div>
        <div className="request-form request-form--photos-form">
            <div className="form-device">
                <Device/>
            </div>
            <div className="photos-form">
                <div className="photos-form__text">Загрузи фотографии для оценки состояния</div>
                <form className="photos-form__holder" ref={form}>
                    <div className="photos-form__photo" onClick={() => {
                        uploadImage('fr')
                    }}>
                        <input name={'fr'} type="file" ref={frInput} onChange={(e) => {
                            onSelectFile(e, 'fr')
                        }} accept=".jpg,.jpeg,.png"/>
                        <div className="photos-form__photo-zone">
                            {images.fr === null ?
                                <>
                                    <div className="photos-form__photo-zone--icon">
                                        <img src={upload} alt=""/>
                                    </div>
                                    <div className="photos-form__photo-zone--text">Загрузить фото</div>
                                </> : <img src={images.fr} alt=""/>}
                        </div>
                        <div className="photos-form__photo-title">Лицевая сторона</div>
                    </div>
                    <div className="photos-form__photo" onClick={() => {
                        uploadImage('bk')
                    }}>
                        <input name={'bk'} type="file" ref={bkInput} onChange={(e) => {
                            onSelectFile(e, 'bk')
                        }} accept=".jpg,.jpeg,.png"/>
                        <div className="photos-form__photo-zone">
                            {images.bk === null ?
                                <>
                                    <div className="photos-form__photo-zone--icon">
                                        <img src={upload} alt=""/>
                                    </div>
                                    <div className="photos-form__photo-zone--text">Загрузить фото</div>
                                </> : <img src={images.bk} alt=""/>}
                        </div>
                        <div className="photos-form__photo-title">Задняя сторона</div>
                    </div>
                    <div className="photos-form__photo" onClick={() => {
                        uploadImage('lf')
                    }}>
                        <input name={'lf'} type="file" ref={lfInput} onChange={(e) => {
                            onSelectFile(e, 'lf')
                        }} accept=".jpg,.jpeg,.png"/>
                        <div className="photos-form__photo-zone">
                            {images.lf === null ?
                                <>
                                    <div className="photos-form__photo-zone--icon">
                                        <img src={upload} alt=""/>
                                    </div>
                                    <div className="photos-form__photo-zone--text">Загрузить фото</div>
                                </> : <img src={images.lf} alt=""/>}
                        </div>
                        <div className="photos-form__photo-title">Левая сторона</div>
                    </div>
                    <div className="photos-form__photo" onClick={() => {
                        uploadImage('rg')
                    }}>
                        <input name={'rg'} type="file" ref={rgInput} onChange={(e) => {
                            onSelectFile(e, 'rg')
                        }} accept=".jpg,.jpeg,.png"/>
                        <div className="photos-form__photo-zone">
                            {images.rg === null ?
                                <>
                                    <div className="photos-form__photo-zone--icon">
                                        <img src={upload} alt=""/>
                                    </div>
                                    <div className="photos-form__photo-zone--text">Загрузить фото</div>
                                </> : <img src={images.rg} alt=""/>}
                        </div>
                        <div className="photos-form__photo-title">Правая сторона</div>
                    </div>
                    <div className="photos-form__photo" onClick={() => {
                        uploadImage('tp')
                    }}>
                        <input name={'tp'} type="file" ref={tpInput} onChange={(e) => {
                            onSelectFile(e, 'tp')
                        }} accept=".jpg,.jpeg,.png"/>
                        <div className="photos-form__photo-zone">
                            {images.tp === null ?
                                <>
                                    <div className="photos-form__photo-zone--icon">
                                        <img src={upload} alt=""/>
                                    </div>
                                    <div className="photos-form__photo-zone--text">Загрузить фото</div>
                                </> : <img src={images.tp} alt=""/>}
                        </div>
                        <div className="photos-form__photo-title">Верхняя сторона</div>
                    </div>
                    <div className="photos-form__photo" onClick={() => {
                        uploadImage('bt')
                    }}>
                        <input name={'bt'} type="file" ref={btInput} onChange={(e) => {
                            onSelectFile(e, 'bt')
                        }} accept=".jpg,.jpeg,.png"/>
                        <div className="photos-form__photo-zone">
                            {images.bt === null ?
                                <>
                                    <div className="photos-form__photo-zone--icon">
                                        <img src={upload} alt=""/>
                                    </div>
                                    <div className="photos-form__photo-zone--text">Загрузить фото</div>
                                </> : <img src={images.bt} alt=""/>}
                        </div>
                        <div className="photos-form__photo-title">Нижняя сторона</div>
                    </div>
                </form>
                <div className="photos-form__buttons">
                    <div className="btn btn-orange" onClick={() => {
                        checkResults()
                    }}>
                        {inProgress ? <LoaderSmall color={'white'}/> : <span className="text">Далее</span>}
                    </div>
                </div>
            </div>
        </div>
        {showResult ?
            <ResultPopup success={success} reject={reject}/> : null}
    </>
}