import AsideLayout from "../layouts/AsideLayout";
import RequestLayout from "../layouts/RequestLayout";
import {Auth} from '../views/Auth'
import {Main} from "../views/Main";
import {Create} from "../views/request/Create";
import {Visual} from "../views/request/Visual";
import {Autograde} from "../views/request/Autograde";
import {Tech} from "../views/request/Tech";
import {Customer} from "../views/request/Customer";
import {Success} from "../views/request/Success";
import {Approve} from "../views/request/Approve";
import {TechCode} from "../views/request/TechCode";
export const authRoutes = [
    {
        path: "/auth",
        layout: AsideLayout,
        component: Auth,
        seo: {
            title: 'Авторизация | СдалКупил',
            description: ''
        }
    },
];

export const publicRoutes = [
    {
        path: "/",
        layout: AsideLayout,
        component: Main,
        seo: {
            title: 'Главная | СдалКупил',
            description: ''
        }
    },
    {
        path: "/request",
        layout: RequestLayout,
        component: Create,
        seo: {
            title: 'Новая заявка | СдалКупил',
            description: ''
        }
    },
    {
        path: "/request/visual",
        layout: RequestLayout,
        component: Visual,
        seo: {
            title: 'Визуальная оценка | СдалКупил',
            description: ''
        }
    },
    {
        path: "/request/approve",
        layout: RequestLayout,
        component: Approve,
        seo: {
            title: 'Ожидание подтверждения | СдалКупил',
            description: ''
        }
    },
    {
        path: "/request/autograding",
        layout: RequestLayout,
        component: Autograde,
        seo: {
            title: 'Фото устройства | СдалКупил',
            description: ''
        }
    },
    {
        path: "/request/tech",
        layout: RequestLayout,
        component: Tech,
        seo: {
            title: 'Проверка работоспособности | СдалКупил',
            description: ''
        }
    },
    {
        path: "/request/tech/code",
        layout: RequestLayout,
        component: TechCode,
        seo: {
            title: 'Проверка работоспособности | СдалКупил',
            description: ''
        }
    },
    {
        path: "/request/customer",
        layout: RequestLayout,
        component: Customer,
        seo: {
            title: 'Данные клиента | СдалКупил',
            description: ''
        }
    },
    {
        path: "/request/success",
        layout: RequestLayout,
        component: Success,
        seo: {
            title: 'Заявка создана | СдалКупил',
            description: ''
        }
    },
];

export default [
    ...authRoutes,
    ...publicRoutes
]
