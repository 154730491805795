export const IconPhone = () => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 25H15.5H11" stroke="#680A87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M7 7L8 7L7 7ZM20 2L11 2L11 4L20 4L20 2ZM8 25L8 7L6 7L6 25L8 25ZM20 28L11 28L11 30L20 30L20 28ZM25 25L25 7.00001L23 7L23 25L25 25ZM20 30C22.7614 30 25 27.7614 25 25L23 25C23 26.6569 21.6568 28 20 28L20 30ZM20 4C21.6569 4 23 5.34315 23 7L25 7.00001C25 4.23858 22.7614 2 20 2L20 4ZM11 2C8.23858 2 6 4.23857 6 7L8 7C8 5.34314 9.34314 4 11 4L11 2ZM6 25C6 27.7614 8.23858 30 11 30L11 28C9.34315 28 8 26.6569 8 25L6 25Z"
            fill="#680A87"/>
    </svg>
}
export const IconTablet = () => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2 11L3 11L2 11ZM26 6L6 6L6 8L26 8L26 6ZM3 22L3 11L1 11L1 22L3 22ZM26 25L6 25L6 27L26 27L26 25ZM31 22L31 11L29 11L29 22L31 22ZM26 27C28.7614 27 30.9999 24.7614 31 22L29 22C28.9999 23.6569 27.6568 25 26 25L26 27ZM26 8C27.6568 8 29 9.34315 29 11L31 11C31 8.23859 28.7614 6 26 6L26 8ZM6 6C3.23858 6 1 8.23858 1 11L3 11C3 9.34314 4.34315 8 6 8L6 6ZM1 22C1 24.7614 3.23858 27 6 27L6 25C4.34315 25 3 23.6569 3 22L1 22Z"
            fill="#680A87"/>
        <path d="M23 22H16H9" stroke="#680A87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

}
export const IconLaptop = () => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28 29L28 28L28 29ZM28 28L5 28L5 30L28 30L28 28ZM28 24L5 24L5 26L28 26L28 24ZM31 27C31 25.3431 29.6569 24 28 24L28 26C28.5523 26 29 26.4477 29 27L31 27ZM4 27C4 26.4477 4.44772 26 5 26L5 24C3.34315 24 2 25.3431 2 27L4 27ZM5 28C4.44772 28 4 27.5523 4 27L2 27C2 28.6569 3.34315 30 5 30L5 28ZM28 30C29.6569 30 31 28.6569 31 27L29 27C29 27.5523 28.5523 28 28 28L28 30Z"
            fill="#680A87"/>
        <path
            d="M27 25C27 25.5523 27.4477 26 28 26C28.5523 26 29 25.5523 29 25L27 25ZM4 25C4 25.5523 4.44772 26 5 26C5.55229 26 6 25.5523 6 25L4 25ZM5 10L4 10L5 10ZM27 10L27 25L29 25L29 10L27 10ZM4 10L4 25L6 25L6 10L4 10ZM26 7L7 7.00002L7 9.00002L26 9L26 7ZM29 10C29 8.34315 27.6569 7 26 7L26 9C26.5523 9 27 9.44772 27 10L29 10ZM6 10C6 9.44774 6.44772 9.00002 7 9.00002L7 7.00002C5.34315 7.00002 4 8.34317 4 10L6 10Z"
            fill="#680A87"/>
    </svg>

}
export const IconWatch = () => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21 6L11 6L11 8L21 8L21 6ZM8 21L8 11L6 11L6 21L8 21ZM21 24L11 24L11 26L21 26L21 24ZM26 21L26 11L24 11L24 21L26 21ZM21 26C23.7614 26 26 23.7614 26 21L24 21C24 22.6569 22.6568 24 21 24L21 26ZM21 8C22.6568 8 24 9.34315 24 11L26 11C26 8.23858 23.7614 6 21 6L21 8ZM11 6C8.23857 6 6 8.23857 6 11L8 11C8 9.34314 9.34314 8 11 8L11 6ZM6 21C6 23.7614 8.23858 26 11 26L11 24C9.34315 24 8 22.6569 8 21L6 21Z"
            fill="#680A87"/>
        <path
            d="M1 20L4.76837e-07 20C4.28555e-07 20.5523 0.447716 21 1 21L1 20ZM1 12L1 11C0.734784 11 0.48043 11.1054 0.292894 11.2929C0.105357 11.4804 2.45731e-07 11.7348 2.22545e-07 12L1 12ZM7 12L8 12L8 11L7 11L7 12ZM6.99999 20L6.99999 21L7.99999 21L7.99999 20L6.99999 20ZM7 11L1 11L1 13L7 13L7 11ZM2 20L2 12L2.22545e-07 12L4.76837e-07 20L2 20ZM6.99999 19L1 19L1 21L6.99999 21L6.99999 19ZM7.99999 20L8 12L6 12L5.99999 20L7.99999 20Z"
            fill="#680A87"/>
        <path
            d="M25 20L24 20C24 20.5523 24.4477 21 25 21L25 20ZM25 12L25 11C24.7348 11 24.4804 11.1054 24.2929 11.2929C24.1054 11.4804 24 11.7348 24 12L25 12ZM31 12L32 12L32 11L31 11L31 12ZM31 20L31 21L32 21L32 20L31 20ZM31 11L25 11L25 13L31 13L31 11ZM26 20L26 12L24 12L24 20L26 20ZM31 19L25 19L25 21L31 21L31 19ZM32 20L32 12L30 12L30 20L32 20Z"
            fill="#680A87"/>
        <path d="M20 22H16H12" stroke="#680A87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}


export const IconApple = () => {
    return <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_367_151)">
            <path
                d="M40.1727 10.9088C39.9875 11.0528 36.7167 12.8992 36.7167 17.0048C36.7167 21.7536 40.8786 23.4336 41.0032 23.4752C40.984 23.5776 40.342 25.776 38.8088 28.016C37.4418 29.9872 36.014 31.9552 33.842 31.9552C31.67 31.9552 31.1111 30.6912 28.6037 30.6912C26.1602 30.6912 25.2914 31.9968 23.3047 31.9968C21.3179 31.9968 19.9317 30.1728 18.3378 27.9328C16.4916 25.3024 15 21.216 15 17.3376C15 11.1168 19.0373 7.8176 23.0108 7.8176C25.1221 7.8176 26.8821 9.2064 28.2076 9.2064C29.4693 9.2064 31.4369 7.7344 33.8388 7.7344C34.7491 7.7344 38.0199 7.8176 40.1727 10.9088ZM32.6985 5.1008C33.6919 3.92 34.3946 2.2816 34.3946 0.6432C34.3946 0.416 34.3754 0.1856 34.3339 0C32.7177 0.0608 30.7948 1.0784 29.6354 2.4256C28.7251 3.4624 27.8754 5.1008 27.8754 6.7616C27.8754 7.0112 27.917 7.2608 27.9361 7.3408C28.0383 7.36 28.2044 7.3824 28.3705 7.3824C29.8206 7.3824 31.6445 6.4096 32.6985 5.1008Z"
                fill="#ccc"/>
        </g>
        <defs>
            <clipPath id="clip0_367_151">
                <rect width="26" height="32" fill="white" transform="translate(15)"/>
            </clipPath>
        </defs>
    </svg>

}

export const IconHonor = () => {
    return <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M34.1412 16.5002C34.1412 19.5376 36.4815 22 39.3688 22C42.2557 22 44.5959 19.5376 44.5959 16.5002C44.5959 13.4623 42.2557 11 39.3688 11C36.4815 11 34.1412 13.4623 34.1412 16.5002ZM35.956 16.5002C35.956 14.5171 37.484 12.9099 39.3688 12.9099C41.2535 12.9099 42.7815 14.5171 42.7815 16.5002C42.7815 18.4833 41.2535 20.0909 39.3688 20.0909C37.484 20.0909 35.956 18.4833 35.956 16.5002ZM11.1183 16.5002C11.1183 19.5376 13.4586 22 16.3459 22C19.2327 22 21.573 19.5376 21.573 16.5002C21.573 13.4623 19.2327 11 16.3459 11C13.4586 11 11.1183 13.4623 11.1183 16.5002V16.5002ZM12.9331 16.5002C12.9331 14.5171 14.4611 12.9099 16.3459 12.9099C18.2306 12.9099 19.7585 14.5171 19.7585 16.5002C19.7585 18.4833 18.2306 20.0909 16.3459 20.0909C14.4611 20.0909 12.9331 18.4833 12.9331 16.5002ZM29.8765 11.115V17.8051L25.47 11.115H24.0229V21.8353H25.8381V15.1126L30.2658 21.8353H31.6913V11.115H29.8765ZM47.0459 11.1472V21.8676H48.861V17.0968L52.642 21.8676H55L51.903 17.9594C53.2411 17.5104 54.2104 16.1924 54.2104 14.6357C54.2104 12.7127 52.7308 11.1527 50.9042 11.1476V11.1472H47.0459ZM48.861 13.0571H50.8819C51.7102 13.0571 52.3819 13.7639 52.3819 14.6357C52.3819 15.5075 51.7098 16.2146 50.8812 16.2146H48.861V13.0571ZM6.85351 11.1472V15.4928H2.81514V11.1472H1V21.8676H2.81514V17.4023H6.85351V21.8676H8.66832V11.1472H6.85351Z"
            fill="url(#paint0_linear_367_365)"/>
        <defs>
            <linearGradient id="paint0_linear_367_365" x1="24.1128" y1="5.45435" x2="32.5648" y2="27.149"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF00D0"/>
                <stop offset="0.25" stop-color="#4E73FF"/>
                <stop offset="0.6" stop-color="#00B1FF"/>
                <stop offset="1" stop-color="#00FFFF"/>
            </linearGradient>
        </defs>
    </svg>
}

export const IconSamsung = () => {
    return <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M54.9669 11.2859C55.7024 15.5183 44.2259 21.0605 29.3306 23.6647C14.4375 26.2689 1.76781 24.9477 1.03336 20.7132C0.298922 16.4808 11.7788 10.9397 26.6719 8.33665C41.5661 5.73024 54.2335 7.05249 54.9669 11.2859Z"
            fill="#034EA2"/>
        <path
            d="M40.9489 17.5677L40.873 13.1672H42.2548V18.714H40.268L38.8884 14.1623H38.8583L38.9353 18.714H37.5613V13.1672H39.6351L40.9187 17.5677H40.9489Z"
            className={'text'}
            fill="white"/>
        <path
            d="M15.8238 13.6872L15.0592 18.7722H13.5513L14.586 13.1672H17.0694L18.1008 18.7722H16.5984L15.8551 13.6872H15.8238Z"
            className={'text'}
            fill="white"/>
        <path
            d="M22.3344 17.4522L23.0242 13.1672H25.3012L25.4218 18.7722H24.0266L23.9897 13.7331H23.9607L23.0276 18.7722H21.6112L20.6769 13.7331H20.6468L20.6122 18.7722H19.2136L19.3375 13.1672H21.6156L22.3043 17.4522H22.3344Z"
            className={'text'}
            fill="white"/>
        <path
            d="M11.2005 17.1912C11.2552 17.3268 11.2385 17.5016 11.2128 17.6069C11.1659 17.7929 11.0398 17.9834 10.6692 17.9834C10.3188 17.9834 10.1067 17.7817 10.1067 17.4747V16.9323H8.60878L8.60767 17.366C8.60767 18.6154 9.58767 18.993 10.638 18.993C11.6481 18.993 12.4797 18.6468 12.6114 17.7123C12.6795 17.2282 12.6281 16.9111 12.6058 16.7912C12.3703 15.6179 10.2507 15.2672 10.0933 14.6117C10.0665 14.4996 10.0743 14.3797 10.0877 14.3159C10.1268 14.1377 10.2484 13.9405 10.5978 13.9405C10.9237 13.9405 11.1168 14.1433 11.1168 14.4492C11.1168 14.5523 11.1168 14.7954 11.1168 14.7954H12.5087V14.4021C12.5087 13.1807 11.4171 12.9902 10.6268 12.9902C9.63343 12.9902 8.82197 13.3197 8.67352 14.2318C8.63334 14.4839 8.62776 14.708 8.6858 14.9893C8.93024 16.1334 10.9126 16.4651 11.2005 17.1912Z"
            className={'text'}
            fill="white"/>
        <path
            d="M29.3551 17.1811C29.4097 17.3156 29.3919 17.4859 29.3673 17.5912C29.3216 17.7773 29.1966 17.9644 28.8282 17.9644C28.4833 17.9644 28.2724 17.7627 28.2724 17.4624L28.2713 16.9245H26.789L26.7867 17.3526C26.7867 18.5897 27.7578 18.9628 28.797 18.9628C29.7959 18.9628 30.6197 18.621 30.7503 17.6955C30.8184 17.2147 30.7704 16.9021 30.7458 16.7844C30.5114 15.6224 28.4141 15.2751 28.2579 14.6251C28.2311 14.5131 28.2389 14.3954 28.2523 14.3349C28.2925 14.1545 28.4119 13.9629 28.7579 13.9629C29.0816 13.9629 29.2702 14.1601 29.2702 14.4649C29.2702 14.5669 29.2702 14.8078 29.2702 14.8078H30.6521V14.4178C30.6521 13.2099 29.5694 13.0205 28.7858 13.0205C27.8036 13.0205 26.9988 13.3455 26.8537 14.252C26.8135 14.5008 26.8091 14.7204 26.8671 15.0005C27.1071 16.1334 29.0704 16.4629 29.3551 17.1811Z"
            className={'text'}
            fill="white"/>
        <path
            d="M34.0441 17.9341C34.4314 17.9341 34.552 17.6652 34.5787 17.5274C34.5899 17.4668 34.5933 17.3862 34.5921 17.3133V13.165H36.0041V17.1856C36.0074 17.2887 35.9974 17.5005 35.9918 17.5531C35.8925 18.5997 35.0687 18.9393 34.043 18.9393C33.0161 18.9393 32.1913 18.5997 32.093 17.5531C32.0886 17.5005 32.0785 17.2876 32.0808 17.1856V13.165H33.4916V17.3122C33.4916 17.385 33.4938 17.4668 33.505 17.5262C33.5362 17.6641 33.6534 17.9341 34.0441 17.9341Z"
            className={'text'}
            fill="white"/>
        <path
            d="M45.6915 17.8747C46.0955 17.8747 46.2373 17.6181 46.263 17.4691C46.273 17.4052 46.2764 17.3268 46.2752 17.2562V16.4427H45.7026V15.6247H47.6827V17.1296C47.6816 17.2349 47.6794 17.3122 47.6626 17.4994C47.57 18.5191 46.6882 18.8832 45.6993 18.8832C44.7081 18.8832 43.8286 18.5191 43.7337 17.4994C43.7181 17.3122 43.7159 17.2349 43.7136 17.1296L43.7147 14.7697C43.7147 14.67 43.727 14.494 43.7382 14.3999C43.8621 13.3522 44.7081 13.0149 45.6993 13.0149C46.6893 13.0149 47.5566 13.3499 47.6593 14.3999C47.6772 14.5781 47.6716 14.7697 47.6716 14.7697V14.9568H46.2641V14.6431C46.2652 14.6442 46.2618 14.5097 46.2462 14.4302C46.2228 14.3058 46.1156 14.0212 45.6892 14.0212C45.2818 14.0212 45.1635 14.2901 45.1334 14.4302C45.1167 14.5041 45.11 14.6038 45.11 14.6946V17.2584C45.1088 17.329 45.1133 17.4075 45.1245 17.4713C45.1468 17.6181 45.2874 17.8747 45.6915 17.8747Z"
            className={'text'}
            fill="white"/>
    </svg>

}

export const IconXiaomi = () => {
    return <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M44.6423 4.99995H40.0343C39.837 4.99995 39.6772 5.15889 39.6772 5.35472V26.6457C39.6772 26.8399 39.837 26.9988 40.0343 26.9988H44.6423C44.8379 26.9988 44.9999 26.8399 44.9999 26.6457V5.35472C44.9999 5.15889 44.8379 4.99995 44.6423 4.99995Z"
            fill="#FF6700"/>
        <path
            d="M28.9086 4.99995H11.3559C11.1586 4.99995 11 5.15889 11 5.35472V26.6457C11 26.8399 11.1586 26.9988 11.3559 26.9988H15.9651C16.1613 26.9988 16.3238 26.8399 16.3238 26.6457V9.93543C16.3238 9.74244 16.4824 9.58238 16.6797 9.58238H26.6082C29.4002 9.58238 30.1928 11.7479 30.1928 13.1448V26.6457C30.1928 26.8399 30.3531 26.9988 30.5504 26.9988H35.1568C35.3529 26.9988 35.5138 26.8399 35.5138 26.6457V11.5418C35.5138 10.2959 35.3658 8.50446 33.7784 6.9276C32.1176 5.28149 30.6025 4.99995 28.9086 4.99995Z"
            fill="#FF6700"/>
        <path
            d="M25.6799 13.4803H20.841C20.6437 13.4803 20.4817 13.6393 20.4817 13.834V26.6475C20.4817 26.8411 20.6437 27 20.841 27H25.6799C25.8756 27 26.0359 26.8411 26.0359 26.6475V13.834C26.0359 13.6393 25.8756 13.4803 25.6799 13.4803Z"
            fill="#FF6700"/>
    </svg>

}