import {useContext, useEffect, useState} from 'react'
import {AppContext} from "../store/context";

import trash from '../static/img/icons/conditions/trash-big.svg'
import bad from '../static/img/icons/conditions/bad-big.svg'
import good from '../static/img/icons/conditions/good-big.svg'
import sp from '../static/img/icons/conditions/super-big.svg'
import close from '../static/img/icons/close.svg'

export const Instruction = () => {
    const {context} = useContext(AppContext);

    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        if (context.modal.state.instruction) {
            setShow(true);
            setTimeout(() => {
                setOpen(true)
            }, 100)
        } else {
            setOpen(false);
            setTimeout(() => {
                setShow(false)
            }, 100)
        }
    }, [context.modal.state.instruction]);

    const descriptions = {
        b: '  На дисплее не допускается наличие царапин, На корпусе может быть не более 2 царапин, которые не цепляются за ноготь.',
        c: 'На дисплее допускается наличие мелких царапин. На корпусе допускается наличие царапин и потертостей, небольшое количество неглубоких вмятин и трещин.',
        d: 'На дисплее допускается наличие царапин и потертостей. На корпусе допускается наличие царапин, потертостей и неглубоких вмятин, небольшое количество глубоких вмятин и трещин.',
        g: 'Устройство должно включаться. Сенсор дисплея должен быть в рабочем состоянии и позволять сделать проверку IMEI (*#06#), а также выполнить сброс устройства до заводских настроек.'
    };

    return <div className={`instruction ${show ? 'show' : ''} ${open ? 'open' : ''}`}>
        <div className="instruction-container">
            <div className="instruction-close" onClick={() => {context.modal.dispatch({type: 'toggleInstruction'})}}>
                <img src={close} alt=""/>
            </div>
            <div className="instruction-title">Визуальная оценка</div>
            <div className="instruction-items">
                <div className="instruction-item">
                    <div className="instruction-item__icon">
                        <img src={sp} alt=""/>
                    </div>
                    <div className="instruction-item__title">Отличное</div>
                    <div className="instruction-item__description">
                        {descriptions.b}
                    </div>

                </div>
                <div className="instruction-item">
                    <div className="instruction-item__icon">
                        <img src={good} alt=""/>
                    </div>
                    <div className="instruction-item__title">Хорошее</div>
                    <div className="instruction-item__description">
                        {descriptions.c}
                    </div>
                </div>
                <div className="instruction-item">
                    <div className="instruction-item__icon">
                        <img src={bad} alt=""/>
                    </div>
                    <div className="instruction-item__title">Плохое</div>
                    <div className="instruction-item__description">
                        {descriptions.d}
                    </div>
                </div>
                <div className="instruction-item">
                    <div className="instruction-item__icon">
                        <img src={trash} alt=""/>
                    </div>
                    <div className="instruction-item__title">Требуется ремонт</div>
                    <div className="instruction-item__description">
                        {descriptions.g}
                    </div>
                </div>
            </div>
        </div>
    </div>
}