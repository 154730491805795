import {useHistory} from 'react-router-dom'

import back from '../../static/img/icons/back.svg'
import info from '../../static/img/icons/chat.svg'

export const TopBar = () => {
    const history = useHistory();

    return <div className="request-topbar">
        <div className="request-topbar__back" onClick={() => {history.goBack()}}>
            <img src={back} alt=""/>
        </div>
        <div className="request-topbar__help">
            <a href={'https://t.me/SdalKupil'} target={'_blank'}>
                <img src={info} alt=""/>
            </a>
        </div>
    </div>
}