import React from "react";

import {AppContext} from "../../store/context";
import {TechCodeForm} from "../../components/request/form/TechCode";

export class TechCode extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        setTimeout(() => {
            this.setState({loaded: true})
        }, 100)
    }

    goNext() {
        this.setState({loaded: false});
        this.context.context.request.dispatch({type: 'save'});

        setTimeout(() => {
            this.props.history.push('/request/customer')
        }, 300)
    }

    goMain() {
        const request = this.context.context.request.state.id;
        this.context.context.processor.request.cancel(request, "tech")
            .then(() => {
                this.props.history.push('/');
            })
    }

    render() {
        return <div className={`reveal ${this.state.loaded ? 'reveal--loaded' : ''}`}>
            <TechCodeForm success={() => this.goNext()} reject={() => this.goMain()}/>
        </div>
    }
}

TechCode.contextType = AppContext