import React, {useContext} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {Page404} from "../views/Page404";
import {AppContext} from "../store/context";

import routes from "./index";
import {Loader} from "../components/Loader";

const Routes = () => {
    const {context} = useContext(AppContext);

    return context.user.state.loaded ?
        <Router>
            <Switch>
                {RouteMap()}
                <Route render={() => (
                    <Page404/>
                )}/>
            </Switch>
        </Router> : <Loader/>
};

const RouteMap = () => {
    let map = [];

    routes.forEach((el, index) => {
        map.push(componentRoute(el, index));
    });

    return map
};

const componentRoute = ({layout: Layout, component: Component, path, seo}, index) => {
    return Component ? (<Route key={index} path={path} exact render={(props) => (
        <Layout seo={seo}><Component {...props} /></Layout>
    )}/>) : null;
};

export default Routes;
