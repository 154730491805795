import {useContext} from 'react'
import {AppContext} from "../../../store/context";

import trash from '../../../static/img/icons/conditions/trash-big.svg'
import bad from '../../../static/img/icons/conditions/bad-big.svg'
import good from '../../../static/img/icons/conditions/good-big.svg'
import sp from '../../../static/img/icons/conditions/super-big.svg'

const Result = ({success, reject, hideDisclamer}) => {
    const {context} = useContext(AppContext);

    const currentGrade = context.request.state.grade;

    const gradeText = {
        'b': 'в отличном состоянии',
        'c': 'в хорошем состоянии',
        'd': 'в плохом состоянии',
        'g': 'в состоянии "Требуется ремонт"',
        'cancel': 'не выкупается'
    };

    const gradeSmiley = {
        'b': sp,
        'c': good,
        'd': bad,
        'g': trash,
        'cancel': trash
    };

    return <div className="result">
        <div className="result__smiley">
            <img src={gradeSmiley[currentGrade.grade]} alt=""/>
        </div>
        <div className="result__text">По результатам тестирования, устройство {gradeText[currentGrade.grade]}</div>
        <div className="result__price">
            <div className="result__price-title">Стоимость:</div>
            <div className="result__price-amount">{currentGrade.price} ₽</div>
        </div>
        <div className="result__buttons">
            <a href={'https://t.me/SdalKupil'} target={"_blank"} className="btn btn-white">
                <span className="text">Не согласен с оценкой</span>
            </a>
            <div className="btn btn-white" onClick={() => {
                reject()
            }}>
                <span className="text">Клиент отказался</span>
            </div>
            {currentGrade.grade !== 'cancel'?
            <div className="btn btn-orange" onClick={() => {
                success()
            }}>
                <span className="text">Далее</span>
            </div> : null}
        </div>
    </div>
}

export const ResultSection = ({success, reject, hideDisclamer}) => {
    return <>
        <div className="result-section">
            <div className="request-title">Результаты оценки</div>
            <div className="request-form request-form--result">
                <Result success={success} reject={reject} hideDisclamer={hideDisclamer}/>
            </div>
        </div>
    </>
}

export const ResultPopup = ({open, success, reject}) => {
    return <div className={`result-pp`}>
        <Result success={success} reject={reject}/>
    </div>
};