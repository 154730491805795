import Token from "../components/static/token"

export class AuthAware {
    async checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        }

        if (response.status === 401 || response.status === 400) {
            let json = await response.json();
            let error = new Error(json.error.message);
            error.code = response.status;

            throw error
        }

        if (response.status === 403) {
            Token.logout();
            localStorage.removeItem('user');
            localStorage.removeItem('request');
            window.location.replace('/auth');

            let error = new Error("Пожалуйста, авторизуйтесь еще раз");
            throw error
        }

        if (response.status > 403) {
            let error = new Error(response.statusText);
            error.code = response.status;

            throw error
        }
    }

    async fetchJSON(url, options, omitType) {
        if (options === undefined) {
            options = {};
        }

        // options.credentials = 'include';

        let headers = options.headers || {};

        const token = Token.getToken()

        if (token) {
            headers['Authorization'] = token;
        }

        if (omitType !== true) {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
        }

        return fetch(url, {headers, ...options})
            .then(response => this.checkStatus(response))
            .then(response => response.json())
    }
}