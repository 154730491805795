import React from 'react'

import {AppContext} from "../../store/context";
import {ApproveForm} from "../../components/request/form/Approve";

export class Approve extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        setTimeout(() => {
            this.setState({loaded: true})
        }, 100)
    }

    goNext() {
        this.setState({loaded: false});
        setTimeout(() => {
            this.props.history.push('/request/visual')
        }, 300)
    }

    render() {
        return <div className={`reveal ${this.state.loaded ? 'reveal--loaded' : ''}`}>
            <ApproveForm callback={() => this.goNext()}/>
        </div>
    }
}

Approve.contextType = AppContext;