import {useContext, useEffect, useState} from 'react'
import Select from 'react-select'

import {IconApple, IconHonor, IconPhone, IconSamsung, IconTablet, IconWatch, IconXiaomi} from '../../Icons'
import {AppContext} from "../../../store/context";

import sp from "../../../static/img/icons/conditions/super.svg"
import good from "../../../static/img/icons/conditions/good.svg"
import trash from "../../../static/img/icons/conditions/trash.svg"
import btn from '../../../static/img/icons/btn-right.svg'

export const DeviceForm = ({callback}) => {
    const {context} = useContext(AppContext);
    const [form, setForm] = useState({
        type: null,
        brand: {
            id: null,
            title: null
        },
        model: {
            id: null,
            title: null,
        },
        memory: {
            title: null,
        },
    });

    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [memory, setMemory] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (form.type !== null) {
            context.processor.catalog.getBrands(form.type).then(res => {
                const brands = res.map(el => {
                    return {label: el.name, value: el.id}
                });

                setBrands(brands)
            }).catch(err => {
                context.toast.error(err.message)
            })
        }
    }, [form.type]);

    //LOAD MODELS
    useEffect(() => {
        if (form.type !== null && form.brand.id !== null) {
            context.processor.catalog.getModels(form.type, form.brand.id).then(res => {
                const hashmap = [];

                res.map(el => {
                    let n = el.name.trim();
                    if (el.combined_name) {
                        n = el.combined_name.trim();
                    }

                    if (!hashmap[n]) {
                        hashmap[n] = {label: n, value: []}
                    }

                    hashmap[n].value.push({
                        value: el.id, label: el.name, memory_size: el.memory_size
                    })
                });
                setModels(Object.values(hashmap))
            }).catch(err => {
                context.toast.error(err.message)
            })
        }
    }, [form.brand.id, form.type]);

    useEffect(() => {
        if (form.model.id) {
            context.processor.catalog.getModel(form.model.id).then(res => {
                res.map(el => {
                    el.buyout_price_g = `${el.buyout_price_d * 0.1} - ${el.buyout_price_b * 0.3}`

                    return el
                })

                setResults(res)
            });
        } else {
            setResults([])
        }
    }, [form.model.id]);

    const selectType = (id) => {
        setModels([]);
        setMemory([]);
        setBrands([]);

        setForm({...form, type: id, memory: {title: null}, model: {title: null, id: null}})
    };

    const selectBrand = (el) => {
        setModels([]);
        setMemory([]);

        if(el === null) {
            setForm({...form, brand: {title: null, id: null}, memory: {title: null}, model: {title: null, id: null}})
            return
        }

        setForm({...form, brand: {title: el.label, id: el.value}, memory: {title: null}, model: {title: null, id: null}})
    };

    const selectModel = (el) => {
        if(el === null) {
            setForm({...form, memory: {title: null}, model: {title: null, id: null}});
            return
        }

        if (el.value.length == 1) {
            setMemory([]);
            setForm({...form, memory: {title: null}, model: {title: el.label, id: el.value[0].value}});
            return
        }

        const memory = el.value.map(e => {
            return {label: e.memory_size + 'GB', value: e.value}
        });

        setMemory(memory);
        setForm({...form, memory: {title: null}, model: {title: el.label, id: null}})
    };

    const selectMemory = (el) => {
        setForm({...form, memory: {title: el.label}, model: {...form.model, id: el.value}})
    };

    const selectDevice = (el) => {
        const data = {
            model: form.model.id,
            type: form.type
        };

        context.processor.request.createRequest(data).then(res => {
            const request = {
                id: res.id,
                request: res,
                device: el,
                grade: null,
            };

            context.request.dispatch({type: 'setRequest', payload: request});

            callback(res.is_approved)
        }).catch(err => {
            context.toast.error(err.message)
        })
    }

    return <>
        <div className="request-title">Оценка устройства</div>
        <div className="request-form request-form--device-form">
            <div className="device-form">
                <div className="device-form__grid">
                    <div className="device-form__section device-form__section--device">
                        <div className="device-form__section-title">Тип устройства</div>
                        <div className="device-form__section-body">
                            <div className="device-form__buttons">
                                <DeviceType title={'Смартфон'}
                                            active={form.type == 1}
                                            icon={<IconPhone/>}
                                            onClick={() => {
                                                selectType(1)
                                            }}/>
                                <DeviceType title={'Планшет'}
                                            active={form.type == 2}
                                            icon={<IconTablet/>}
                                            onClick={() => {
                                                selectType(2)
                                            }}/>
                                <DeviceType title={'Умные часы'}
                                            active={form.type == 3}
                                            icon={<IconWatch/>}
                                            onClick={() => {
                                                selectType(3)
                                            }}/>
                            </div>
                        </div>
                    </div>
                    <div className="device-form__section device-form__section--brand">
                        <div className="device-form__section-title">Бренд</div>
                        <div className="device-form__section-body">
                            <div className="device-form__buttons">
                                <DeviceBrand
                                    title={'Apple'}
                                    icon={<IconApple/>}
                                    active={form.brand.id == 18}
                                    onClick={() => {
                                        selectBrand({label: 'Apple', value: 18})
                                    }}/>
                                <DeviceBrand
                                    title={'Honor'}
                                    icon={<IconHonor/>}
                                    active={form.brand.id == 320}
                                    onClick={() => {
                                        selectBrand({label: 'Honor', value: 320})
                                    }}/>
                                <DeviceBrand
                                    title={'Samsung'}
                                    icon={<IconSamsung/>}
                                    active={form.brand.id == 19}
                                    onClick={() => {
                                        selectBrand({label: 'Samsung', value: 19})
                                    }}/>
                                <DeviceBrand
                                    title={'Xiaomi'}
                                    icon={<IconXiaomi/>}
                                    active={form.brand.id == 140}
                                    onClick={() => {
                                        selectBrand({label: 'Xiaomi', value: 140})
                                    }}/>
                            </div>
                            <Select
                                classNamePrefix={'device-form__selector'}
                                options={brands}
                                value={brands.filter(function(option) {
                                    return option.value == form.brand.id;
                                })}
                                isClearable={true}
                                backspaceRemovesValue={true}
                                isDisabled={brands.length === 0}
                                placeholder={brands.length === 0 ? '-' : 'Выбрать'}
                                onChange={(el) => {selectBrand(el)}}
                            />
                        </div>
                    </div>
                    <div className="device-form__section device-form__section--model">
                        <div className="device-form__section-title">Модель устройства</div>
                        <div className="device-form__section-body">
                            <Select
                                classNamePrefix={'device-form__selector'}
                                options={models}
                                value={models.filter(function(option) {
                                    return option.label === form.model.title;
                                })}
                                isClearable={true}
                                backspaceRemovesValue={true}
                                isDisabled={models.length === 0}
                                placeholder={models.length === 0 ? '-' : 'Выбрать'}
                                onChange={(el) => {selectModel(el)}}
                            />
                        </div>
                    </div>
                    {memory.length > 0 ?
                    <div className="device-form__section device-form__section--memory">
                        <div className="device-form__section-title">Объём памяти</div>
                        <div className="device-form__section-body">
                            <Select
                                classNamePrefix={'device-form__selector'}
                                options={memory}
                                value={memory.filter(function(option) {
                                    return option.label === form.memory.title;
                                })}
                                isDisabled={memory.length === 0}
                                placeholder={memory.length === 0 ? '-' : 'Выбрать'}
                                onChange={(el) => {selectMemory(el)}}
                            />
                        </div>
                    </div> : null}
                </div>
                <div className="device-form__section device-form__section--search">
                    <div className="device-form__section-title">Результат поиска</div>
                    <div className="device-form__section-body">
                        <div className="device-form__search-result">
                            {results.map(el => {
                                return <div className="device-form__search-item" onClick={() => {
                                    selectDevice(el)
                                }}>
                                    <div className="device-form__search-title">{el.exchange_name}</div>
                                    <div className="device-form__search-conditions">
                                        <div className="device-form__search-condition">
                                            <div className="device-form__search-icon">
                                                <img src={sp} alt=""/>
                                            </div>
                                            <div className="device-form__search-price">{el.buyout_price_b} ₽</div>
                                        </div>
                                        <div className="device-form__search-condition">
                                            <div className="device-form__search-icon">
                                                <img src={sp} alt=""/>
                                            </div>
                                            <div className="device-form__search-price">{el.buyout_price_c} ₽</div>
                                        </div>
                                        <div className="device-form__search-condition">
                                            <div className="device-form__search-icon">
                                                <img src={good} alt=""/>
                                            </div>
                                            <div className="device-form__search-price">{el.buyout_price_d} ₽</div>
                                        </div>
                                        <div className="device-form__search-condition">
                                            <div className="device-form__search-icon">
                                                <img src={trash} alt=""/>
                                            </div>
                                            <div className="device-form__search-price">{el.buyout_price_g} ₽
                                            </div>
                                        </div>
                                    </div>
                                    <div className="device-form__search-select_icon">
                                        <img src={btn} alt=""/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                {results.length === 1 ? <div className="device-form__buttons">
                    <div className="btn btn-orange" onClick={() => {
                        selectDevice(results[0])
                    }}>
                        <span className="text">Далее</span>
                    </div>
                </div> : null}
            </div>
        </div>
    </>
};

const DeviceType = ({active, title, icon, onClick}) => {
    return <div className={`device-form__device ${active ? 'active' : ''}`} onClick={onClick}>
        <div className="device-form__device-title">{title}</div>
        <div className="device-form__device-icon">{icon}</div>
    </div>
};

const DeviceBrand = ({active, title, icon, onClick}) => {
    return <div className={`device-form__brand ${active ? 'active' : ''}`} onClick={onClick}>
        <div className="device-form__brand-icon">{icon}</div>
        <div className="device-form__brand-title">{title}</div>
    </div>
};
