import React, {useContext} from "react";
import {useLocation} from 'react-router-dom'

import {ToastContainer} from 'react-toastify';
import {Helmet} from "react-helmet";
import {Sidebar} from "../components/request/Sidebar";
import {Instruction} from "../components/Instruction";
import {PayModal} from "../components/PayModal";
import {TopBar} from "../components/request/TopBar";

import 'react-toastify/dist/ReactToastify.css';
import {AppContext} from "../store/context";

const Main = ({className, children}) => (
    <div className={className}>{children}</div>
);

const RequestLayout = ({seo, children}) => {
    const {context} = useContext(AppContext);
    const {pathname} = useLocation();

    if (!context.user.state.isAuth) {
        window.location.replace('/auth');
        return;
    }


    return <React.Fragment>
        <Helmet>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description}/>
        </Helmet>
        <Main className={`request ${pathname.includes('success') ? 'request--success' : ''}`}>
            <div className="request-content">
                <Sidebar/>
                <TopBar/>
                <div className="request-inner">
                    {children}
                </div>
            </div>
            <Instruction/>
            <PayModal/>

        </Main>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </React.Fragment>
};

export default RequestLayout;
