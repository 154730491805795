export const UserInitialState = {
    name: null,
    autograding: 0,
    showCard: 0,
    isAuth: false,
    loaded: false,
    isGoodcom: false,
    isRobot: false,
    sbpActive: false,
};

export const UserReducer = (state, action) => {
    switch (action.type) {
        case 'setUser':
            return {
                ...state,
                name: action.payload.username || '-',
                autograding: action.payload.autograding || 0,
                showCard: action.payload.showCard || 0,
                isGoodcom: action.payload.isGoodcom,
                isRobot: action.payload.isRobot,
                sbpActive: action.payload.sbpActive,
                isAuth: true,
                loaded: true,
            };
        case 'loaded':
            return {...state, loaded: true};
        default:
            throw new Error();
    }
};


export const ModalInitialState = {
    instruction: false,
    searcher: false,
    pay: false
};

export const ModalReducer = (state, action) => {
    switch (action.type) {
        case 'toggleInstruction':
            return {...state, instruction: !state.instruction};
        case 'toggleSearcher':
            return {...state, searcher: !state.searcher};
        case 'togglePay':
            return {...state, pay: !state.pay};
        default:
            throw new Error();
    }
};

export const RequestInitialState = {
    id: null,
    request: null,
    device: null,
    grade: null,
};

export const RequestReducer = (state, action) => {
    switch (action.type) {
        case 'setGrade':
            return {...state, grade: action.payload};
        case 'setRequest':
            return action.payload;
        case 'save':
            localStorage.setItem('request', JSON.stringify(state));
            return state;
        case 'load':
            const request = JSON.parse(localStorage.getItem('request'));
            if (request && request.id != null) {
                return request
            }
            return RequestInitialState;
        case 'clear':
            localStorage.removeItem('request');
            return RequestInitialState;
        default:
            throw new Error();
    }
};
