import {useContext, useEffect, useState,} from 'react'
import {AppContext} from "../../../store/context";
import {ResultPopup, ResultSection} from "./Result";
import {LoaderSmall} from "../../Loader";
import {Device} from "../Device";
import OtpInput from "react-otp-input";

import ok from '../../../static/img/icons/checkmark.svg'
import bad from '../../../static/img/icons/close.svg'

import styles from './TechCode.module.css'

export const TechCodeForm = ({success, reject}) => {
    const {context} = useContext(AppContext);

    const [grade, setGrade] = useState(context.request.state.grade?.grade)
    const [code, setCode] = useState(null);
    const [inProgress, setProgress] = useState(false);
    const [showResult, setShowResult] = useState(false);

    const [resAutograding, setResAutograding] = useState(null)
    const [resTech, setResTech] = useState(null)

    const checkResults = async () => {
        if (inProgress) return

        if (!code || code.length < 5) {
            context.toast.error("Пожалуйста, введите код для проверки")
            return
        }
        setResTech(null)
        setResAutograding(null)
        setProgress(true);

        if (grade !== 'g') {
            await checkAutograding()
        }

        await checkTechResults()

        setProgress(false);
    }

    useEffect(() => {
        if (grade !== 'g') {
            if (resTech !== null && resAutograding !== null) {
                if (resTech === true && resAutograding === true) {
                    setShowResult(true)
                    return
                }

                context.toast.error("Что-то пошло не так при технической проверке, попробуйте позже")
            }
        } else {
            if (resTech !== null) {
                if (resTech === true) {
                    setShowResult(true)
                    return
                }

                context.toast.error("Что-то пошло не так при технической проверке, попробуйте позже")
            }
        }

    }, [resTech, resAutograding])

    const checkAutograding = () => {
        const request = context.request.state.id;

        if (!request) reject()

        return context.processor.request.checkGradeByCode(request, code)
            .then(res => {
                    setResAutograding(true)
                    context.request.dispatch({
                        type: 'setGrade',
                        payload: {grade: res.grade, price: res.price}
                    })
                }
            )
            .catch(err => {
                context.toast.error(err.message)
                setResAutograding(false)
            })

    }

    const checkTechResults = () => {
        const request = context.request.state.id;

        if (!request) reject()

        return context.processor.request.checkTechResults(request, code)
            .then(res => {
                    setResTech(true)
                    context.request.dispatch(
                        {
                            type: 'setGrade',
                            payload: {grade: res.grade, price: res.price}
                        }
                    )
                }
            )
            .catch(err => {
                context.toast.error(err.message)
                setResTech(false)
            })
    }

    return showResult && context.settings.isDesktop ?
        <ResultSection success={success} reject={reject} hideDisclamer={true}/> : <>
            <div className="request-title">Проверка работоспособности</div>
            <div className="request-form request-form--tech-form">
                <div className="form-device">
                    <Device/>
                </div>
                <div className="tech-form">
                    <div className="tech-form__text">
                        Введи код для технической проверки устройства
                    </div>
                    <div className="tech-form__code">
                        <div className="tech-form__code-code">
                            <OtpInput numInputs={5}
                                      inputStyle={'customer-confirm-form__code-block'}
                                      placeholder={'-----'}
                                      value={code}
                                      isDisabled={inProgress}
                                      onChange={(code) => {
                                          setCode(code)
                                      }}/>
                        </div>
                        {inProgress || resAutograding !== null || resTech !== null ? <div className={styles.checks}>
                            {context.request.state.grade?.grade !== 'g' ? <div className={styles.check}>
                                <div className={styles.checkIcon}><CheckIcon state={resAutograding}/></div>
                                <div className={styles.checkText}>Проверка autograding</div>
                            </div> : null}
                            <div className={styles.check}>
                                <div className={styles.checkIcon}><CheckIcon state={resTech}/></div>
                                <div className={styles.checkText}>Техническая проверка</div>
                            </div>
                        </div> : null}
                    </div>
                    <div className="tech-form__buttons">
                        <div className="btn btn-orange" onClick={() => checkResults()}>
                            {inProgress ? <LoaderSmall color={'white'}/> : <span className="text">Далее</span>}
                        </div>
                    </div>
                </div>
            </div>
            {showResult && !context.settings.isDesktop ?
                <ResultPopup success={success} reject={reject}/> : null}
        </>
}

const CheckIcon = ({state}) => {
    switch (state) {
        case true:
            return <img className={styles.checkIconOk} src={ok} alt=""/>
        case false:
            return <img className={styles.checkIconBad} src={bad} alt=""/>
        case null:
        default:
            return <LoaderSmall color={'purple'}/>
    }
}