import {useContext, useEffect, useState} from 'react'
import {AppContext} from "../../../store/context";
import {ResultPopup, ResultSection} from "./Result";
import {LoaderSmall} from "../../Loader";
import {Device} from "../Device";
import qr from "../../../static/img/icons/qr.svg"

export const TechForm = ({success, reject}) => {
    const {context} = useContext(AppContext);

    const [code, setCode] = useState(null);
    const [inProgress, setProgress] = useState(false);
    const [showResult, setShowResult] = useState(false);

    useEffect(() => {
        const request = context.request.state.id;

        if (!request) {
            reject()
        }

        context.processor.request.getTechCode(request).then(res => {
            setCode(res.key)
        }).catch(err => {
            context.toast.error(err.message)
        })
    }, []);

    const checkResults = () => {
        if (inProgress) {
            return
        }

        const request = context.request.state.id;

        if (!request) {
            reject()
        }

        setProgress(true);

        context.processor.request.checkTechResults(request, code).then(res => {
            context.request.dispatch({type: 'setGrade', payload: {grade: res.grade, price: res.price}});
            setShowResult(true)
        }).catch(err => {
            context.toast.error(err.message)
        }).finally(() => {
            setProgress(false)
        })
    }

    return showResult && context.settings.isDesktop ?
        <ResultSection success={success} reject={reject} hideDisclamer={true}/> : <>
            <div className="request-title">Проверка работоспособности</div>
            <div className="request-form request-form--tech-form">
                <div className="form-device">
                    <Device/>
                </div>
                <div className="tech-form">
                    <div className="tech-form__qr">
                        <img src={qr} alt=""/>
                    </div>
                    <div className="tech-form__text">Отсканируй QR-код принимаемым устройством и установи приложение.
                    </div>
                    <div className="tech-form__code">
                        <div className="tech-form__code-text">После запуска введи код</div>
                        <div className="tech-form__code-code">{!code ? <LoaderSmall/> : code}</div>
                    </div>
                    <div className="tech-form__buttons">
                        <div className="btn btn-orange" onClick={() => {
                            checkResults()
                        }}>
                            {inProgress ? <LoaderSmall color={'white'}/> : <span className="text">Далее</span>}
                        </div>
                    </div>
                </div>
            </div>
            {showResult && !context.settings.isDesktop ?
                <ResultPopup success={success} reject={reject}/> : null}
        </>
}