import {useContext} from 'react'
import {AppContext} from "../../store/context";
import sp from "../../static/img/icons/conditions/super.svg"
import good from "../../static/img/icons/conditions/good.svg"
import bad from "../../static/img/icons/conditions/bad.svg"
import trash from "../../static/img/icons/conditions/trash.svg"

export const Device = () => {
    const {context} = useContext(AppContext);
    const icons = {
        'b': sp,
        'c': good,
        'd': bad,
        'g': trash,
        'cancel': trash,
    };

    const price_b =  context.request.state.device ? (context.request.state.request.price_b || context.request.state.device.buyout_price_b) : null;
    const price_c =  context.request.state.device ? (context.request.state.request.price_c || context.request.state.device.buyout_price_c ) : null;
    const price_d =  context.request.state.device ? (context.request.state.request.price_d || context.request.state.device.buyout_price_d ) : null;

    const price_g = context.request.state.device ? `${price_d * 0.1} - ${price_b * 0.3}` : null;

    return context.request.state.device ?
        <div className="request-sidebar__device">
            <div className="request-sidebar__device-text">Выбрано устройство</div>
            <div className="request-sidebar__device-title">{context.request.state.device.exchange_name}</div>
            <div className="request-sidebar__device-prices">
                {context.request.state.grade ?
                    <div className="request-sidebar__price">
                        <div className="request-sidebar__price-icon">
                            <img src={icons[context.request.state.grade.grade]} alt=""/>
                        </div>
                        <div className="request-sidebar__price-price">{context.request.state.grade.price} ₽
                        </div>
                    </div>
                    : <>
                        <div className="request-sidebar__price">
                            <div className="request-sidebar__price-icon">
                                <img src={sp} alt=""/>
                            </div>
                            <div
                                className="request-sidebar__price-price">{price_b} ₽
                            </div>
                        </div>
                        <div className="request-sidebar__price">
                            <div className="request-sidebar__price-icon">
                                <img src={good} alt=""/>
                            </div>
                            <div
                                className="request-sidebar__price-price">{price_c} ₽
                            </div>
                        </div>
                        <div className="request-sidebar__price">
                            <div className="request-sidebar__price-icon">
                                <img src={bad} alt=""/>
                            </div>
                            <div
                                className="request-sidebar__price-price">{price_d} ₽
                            </div>
                        </div>
                        <div className="request-sidebar__price">
                            <div className="request-sidebar__price-icon">
                                <img src={trash} alt=""/>
                            </div>
                            <div
                                className="request-sidebar__price-price">{price_g} ₽
                            </div>
                        </div>
                    </>}
            </div>
        </div> : null
}