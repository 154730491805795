import {useContext, useEffect, useState} from 'react'
import {AppContext} from "../store/context";
import {useHistory} from 'react-router-dom'

import close from '../static/img/icons/close.svg'
import {LoaderSmall} from "./Loader";

export const Searcher = () => {
    const {context} = useContext(AppContext);
    const history = useHistory();

    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const [code, setCode] = useState('');

    useEffect(() => {
        if (context.modal.state.searcher) {
            setShow(true);
            setTimeout(() => {
                setOpen(true)
            }, 100)
        } else {
            setOpen(false);
            setTimeout(() => {
                setShow(false)
            }, 100)
        }
    }, [context.modal.state.searcher]);

    const search = () => {
        if(inProgress) {
            return
        }

        setInProgress(true);

        context.processor.request.search(code).then(res => {
            const request = {
                id: res.request.id,
                request: res.request,
                device: res.device,
                grade: null,
            };

            context.request.dispatch({type: 'setRequest', payload: request});
            setTimeout(() => {
                context.request.dispatch({type: 'save'});
                context.modal.dispatch({type: 'toggleSearcher'});

                res.request.is_approved == 1
                    ? history.push('/request/visual')
                    : history.push('/request/approve');
            }, 300)
        }).catch(err => {
            setInProgress(false);
            context.toast.error(err.message);
        })
    }


    return <div className={`searcher ${show ? 'show' : ''} ${open ? 'open' : ''}`}>
        <div className="searcher-container">
            <div className="searcher-close" onClick={() => {context.modal.dispatch({type: 'toggleSearcher'})}}>
                <img src={close} alt=""/>
            </div>
            <div className="searcher-title">Загрузить заявку с сайта</div>
            <div className="searcher-body">
                <div className="searcher-input">
                    <div className="input">
                        <input type="text" name="serial" value={code} onChange={(e) => {setCode(e.target.value)}} placeholder={'Код из СМС'}/>
                    </div>
                </div>
            </div>
            <div className="searcher-buttons">
                <div className="btn btn-orange" onClick={() => {search()}}>
                    {inProgress ? <LoaderSmall color={'white'}/> : <span className="text">Искать</span>}
                </div>
            </div>
        </div>
    </div>
}