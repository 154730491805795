import React from 'react'
import {AppContext} from "../store/context";
import Token from '../components/static/token'

export class Auth extends React.Component {
    constructor() {
        super()

        this.process = this.process.bind(this)
    }

    process(event) {
        event.preventDefault();

        const data = {};
        const formData = new FormData(event.target);

        for (let key of formData.keys()) {
            data[key] = formData.get(key);
        }

        this.context.context.processor.auth.loadUser(data).then(res => {
            Token.setToken(res.key);

            const userdata = {
                username: res.name,
                autograding: res.autograding,
                showCard: res.show_card,
                isGoodcom: res.is_goodcom,
                isRobot: res.is_robot,
                sbpActive: res.sbp_active
            };

            localStorage.setItem('user', JSON.stringify(userdata));

            this.context.context.user.dispatch({type: 'setUser', payload: userdata});


            this.props.history.push("/")
        }).catch(err => {
            this.context.context.toast.error(err.message);
        })
    }

    render() {
        return <div className="auth">
            <form onSubmit={this.process} className="auth-form">
                <div className="input">
                    <input type="text" name="email" placeholder={'Введите E-mail'}/>
                </div>
                <div className="input">
                    <input type="password" name="password" placeholder={'Введите пароль'}/>
                </div>
                <button className="btn btn-orange">
                    <span className="text">Войти</span>
                </button>
            </form>
        </div>
    }
}

Auth.contextType = AppContext;