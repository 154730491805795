import {createContext, useReducer, useState, useEffect} from "react";
import {toast} from 'react-toastify'

import {Processor} from "../api/processor";
import {UserInitialState, UserReducer, RequestReducer, RequestInitialState, ModalInitialState, ModalReducer} from "./reducers";

export const AppContext = createContext();
export const AppContextValue = () => {
    const [userState, userDispatch] = useReducer(UserReducer, UserInitialState);
    const [requestState, requestDispatch] = useReducer(RequestReducer, RequestInitialState);
    const [modalState, modalDispatch] = useReducer(ModalReducer, ModalInitialState)
    const isDesktop = useCheckMobileScreen();

    return {
        settings: {
            isDesktop: isDesktop
        },
        request: {
            state: requestState,
            dispatch: requestDispatch,
        },
        user: {
            state: userState,
            dispatch: userDispatch
        },
        modal: {
            state: modalState,
            dispatch: modalDispatch
        },
        toast: toast,
        processor: Processor
    }
};


const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width > 768);
};
