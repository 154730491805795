import {useContext} from 'react'
import {AppContext} from "../store/context";
import Token from "./static/token";
import exit from '../static/img/icons/exit.svg'

export const TopBar = () => {
    const {context} = useContext(AppContext);

    const logout = () => {
        Token.logout();
        context.request.dispatch({type: 'clear'});

        localStorage.removeItem('user');
        localStorage.removeItem('request');

        window.location.reload()
    }

    return <div className="top">
        {context.user.state.isAuth ? <>
            <div className="top-user">{context.user.state.name}</div>
            <div className="top-exit" onClick={() => {
                logout()
            }}>
                <div className="top-exit__icon">
                    <img src={exit} alt=""/>
                </div>
                <div className="top-exit__text">Выйти
                </div>
            </div>
        </> : <div className="top-clearfix"/>}
    </div>
}